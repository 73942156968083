import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/ArticleLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <blockquote>
      <p parentName="blockquote">{`“The man, however, who, instead of doing what he is told, broods speculating
on the metaphysics of him who calls him to his work, stands leaning his back
against the door by which the Lord would enter to help him.”`}</p>
    </blockquote>
    <p>{`I have been that man. Heaven knows I still struggle not to be that man. I’ve
spent much time, energy and worry in fighting against my inner desire to “know”
to “understand” before I act. Where it is the action that creates understanding.
For it is by obeying the Son of God that the wickedness in me, the darkness in
me will be replaced with His light.`}</p>
    <p>{`It is my pride that keeps me from Him. It is my sin, which at its core is pride,
that barricades me from the very salvation which I desperately need. I once
thought that barricade was my mental illness. That I simply couldn’t understand
God’s doctrine because I was too faulty to understand it. I had then hoped that
Jesus would break through my clouded mind and bless me with the enlightenment,
relief, and peace I needed to act. However,`}</p>
    <blockquote>
      <p parentName="blockquote">{`“To free a man from suffering, he must be set right, put in health; and the
health at the root of man's being, his rightness, is to be free from
wrongness, that is, from sin.“.`}</p>
    </blockquote>
    <p>{`Suffering is the natural consequence of sin, namely being out of order with
God’s will. I once feared that suffering was a punishment from God, either
punishment from past sin or simply a “test”. I no longer believe that. I now
believe that suffering is the natural result of disobeying God. Not that He
punishes us for our actions, but that by committing a particular action (or
omitting a particular behavior) we distance ourselves from Him and as a result,
we experience suffering. That is why seeking His will is the only path by which
to experience the joy and peace He created us for.`}</p>
    <blockquote>
      <p parentName="blockquote">{`“When misery drives a man to call out to the source of his life,—…—the answer,
I think, will come in a quickening of his conscience.”`}</p>
    </blockquote>
    <p>{`I am reminded of Alma the Younger, who wrote:`}</p>
    <blockquote>
      <p parentName="blockquote">{`“But I was racked with eternal torment, for my soul was harrowed up to the
greatest degree and racked with all my sins.”`}</p>
    </blockquote>
    <p>{`Perhaps the only way to peace is repentance or change; I believe true repentance
is intimately tied to change. Perhaps when we pray the relief of our suffering
God answers with a sharpened conscience or “increased light”. Not because He
wills our pain but because He knows the only way to relieve that pain is for us
to change our character. To remove our sins, not be buffered from the natural
consequence or result of those sins. “—and thou shalt call his name Jesus; for
he shall save his people from their sins.—Matthew i. 21.” not just the
consequences of those sins. I have at times been painfully aware of the weight
of my sins, of the depth of hurt I have caused. To myself and to those I have
loved. I have also been at the opposite end of that spectrum, rationalizing and
minimizing the evil inside of me. It is then my conscience is weakest, it was
then I had greatest cause for fear as I had no compass pointing me back to God.
Thankfully though, God is merciful, He is a “fisher of men”, a “shepherd”, and
has oft come looking for me. C.S. Lewis said,`}</p>
    <blockquote>
      <p parentName="blockquote">{`“But pain insists upon being attended to. God whispers to us in our pleasures,
speaks in our conscience, but shouts in our pains: it is his megaphone to
rouse a deaf world.”`}</p>
    </blockquote>
    <p>{`Have you ever considered, that perhaps your pain is calling you back to God? For
He is the only ultimate healer of it. Have you ever considered that your pain
was God’s most effective way of bringing you to lasting peace? I once grew
bitter at this thought, I had distorted it into thinking God had a gun to my
head and the only way I wouldn’t get annihilated was to do what He told me. In
my pride I wanted to defy my perceived oppression. I don’t believe it is like
that. I believe He is urging us, as we are drowning to come aboard to his ship.
And He knows of no other way to keep us from drowning because there is no other
way. There is a natural order of things, that I believe has existed for
eternity. There is a good and an evil, evil being the perversion or absence of
good, not something God created but something He has willed to set right. God
does not wish our suffering. Indeed, He wishes the opposite, He works earnestly
for our infinite joy. He created us for infinite joy. However,`}</p>
    <blockquote>
      <p parentName="blockquote">{`“There can be no deliverance but to come out of his evil dream into the glory
of God.”.`}</p>
    </blockquote>
    <p>{`If I want relief from my suffering, I must repent and be changed. For it is the
sin in me that caused my suffering. I believe when He said “if you love me, keep
my commandments” He was urging us to repent because He knows that is how we
receive joy and peace to our souls.`}</p>
    <blockquote>
      <p parentName="blockquote">{`“The groaning body, wrapt in the garment of hope, will, with outstretched
neck, look for its redemption, and endure.”`}</p>
    </blockquote>
    <p>{`God give me the strength to step away from the door, to let you in, and do every
single thing you command me, even if I don’t understand everything right away.`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.gutenberg.org/files/14453/14453-h/14453-h.htm#SALVATION_FROM_SIN"
      }}>{`Quotes from George MacDonald`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      